<template lang="pug">
div.view_container(v-bind:class="{small: isSmallScreen}")
  Header(v-on:is-small-screen="setIsSmallScreen")
  div.view_title
    span 👍 Thanks, looking good!
  div.sharing(v-show="!isSignedIn && currentProject && participantSlug && currentProject.canInvite")
    div.title(v-if="shareUrl") Share this link to invite others to record a video
    input#input_field_to_copy.hideaway(type="text")
    button.cta_secondary.share_url(v-if="shareUrl" v-on:click="copyShareUrl(shareUrl)" :title="shareUrl")
      .wrapper {{shareUrl}}
    div.controls(v-show="!contactsWithSelectedState && showGoogleSignIn")
      button.done.google_logo(v-on:click="googleSignIn") Or, invite from your address book
    div.loading(v-if="$apollo.loading || loading")
      img(v-bind:src="loadingSrc")
    div.contact_list_container(v-if="contactsWithSelectedState")
      button.cta(v-on:click="invitePeople" ref="invite" style="touch-action: manipulation") Invite from address book
      div.input_container
        input(v-model="searchText" type="text" placeholder="Search your address book"  ref="searchText")
      div.selected_list(v-if="selectedContacts.length > 0")
        span.label selected: &nbsp;&nbsp;
        span(v-for="(contact,index) in selectedContacts")
          span(v-if="index!=0") ,&nbsp;
          a.clickable(v-on:click="toggleSelectedContact(contact)") {{contact.firstName}} {{contact.lastName}}
  div.list(v-if="contactsWithSelectedState")
    div(v-for="(contact,index) in contactsWithSelectedState")
      div.contact(v-if="!contact.selected" v-bind:class="{active: !contact.invited}" v-on:click="toggleSelectedContact(contact)")
        div.name
          span {{truncate(contact.firstName)}}&nbsp;{{truncate(contact.lastName) }}
          span.invited(v-if="contact.invited") &nbsp;&nbsp;&nbsp;already invited
        div.email {{truncate(contact.email)}}
  div.section(v-show="isSignedIn")
    div.description We'll let you know when people do their videos.
    br
    br
    button.done(v-on:click="next('/create')") Create another video
    br
    br
    button.done(v-on:click="next('/')") See your current videos

</template>

<script>
import { INVITE_PEOPLE_WITH_PARTICIPANT_SLUG_MUTATION } from '../graphql/mutations'
import { GET_PARTICIPANT_FROM_SLUG_QUERY,GET_CONTACTS_FROM_SLUG_QUERY } from '../graphql/queries'

import Header from '../components/Header'

import { clearSelection } from '../lib/utils'

export default {
  name: 'Done',
  props: {

  },
  components: {
    Header,
  },
  computed: {
    loadingSrc () {
      return process.env.BASE_URL + "loading_dark.gif"
    },
    isSignedIn () {
      return this.$store.getters.signedIn
    },
    shareUrl () {
      return this.currentProject?.shareUrl
    },
    currentProject () {
      return this.$store.state.currentProject
    },
    currentUser () {
      return this.$store.state.user.user
    },
    participantSlug () {
      return this.$store.state.participantSlug
    },
    inviteButtonTitle () {
      return this.loading ? "Sending invite..." : "Invite"
    },
    currentProjectSlug () {
      return this.participant.project.slug
    },
    showImportAddress () {
      if (this.contacts && this.contacts.length > 0) return false
      return true
    },
    contactsWithSelectedState () {
      if (!this.contacts || this.contacts.length <= 0) return null
      return this.contacts.map((contact) =>
        Object.assign(contact, {invited:
        this.participants.findIndex((p) => p.email == contact.email) >= 0,
        selected: this.selectedContacts.findIndex((p) => p.email == contact.email) >= 0
        })
    )
    },
    selectedContactsForAdding () {
      return this.selectedContacts.map((c)=> {
        return {
          name: (c.firstName != null ? c.firstName : "") + " " + (c.lastName != null ? c.lastName : ""),
          email: c.email
        }
      })
    },
  },
  data () {
    return {
      isSmallScreen: false,
      name: null,
      email: null,
      loading: false,
      participants: [],
      contacts: null,
      showInvite: true,
      searchText: null,
      authCode: null,
      selectedContacts: [],
      showGoogleSignIn: process.env.VUE_APP_SHOW_GOOGLE_SIGNIN,
    }
  },
  apollo: {
    participant () {
      return {
        query: GET_PARTICIPANT_FROM_SLUG_QUERY,
        variables () {
          return {
           slug: this.participantSlug
          }
        },
        skip () {
          return !this.participantSlug
        },
        fetchPolicy: 'cache-and-network',
      }
    },
    contacts () {
      return {
        // gql query
        query: GET_CONTACTS_FROM_SLUG_QUERY,
        variables () {
          return {
            searchText: this.searchText,
            authCode: this.authCode,
            participantSlug: this.participantSlug,
          }
        },
        update: data => {
          if (data.contacts && data.contacts.length > 0) this.showInvite = false
          return data.contacts
        },
        error: (error) => {
          console.log(error.message)
        },
        skip () {
          return !this.participantSlug
        },
        fetchPolicy: 'network-only',
      }
    },
  },
  methods: {
    truncate: function(text) {
      if (!text) return

      const max_length = this.isSmallScreen ? 28 : 18
      const email_match = text.match(/(.+)(@.+\.(?:.{3}|.{2}))/)
      if (email_match) {
        if (email_match[1] && email_match[1].length >max_length) return email_match[1].slice(0,max_length)+"..."+email_match[2]
        return text
      }

      if (text.length > max_length) {
        text = text.slice(0,max_length) + "..."
      }
      return text
    },
    copyShareUrl: function(url) {
      var copyText = document.getElementById("input_field_to_copy");

      copyText.value = url

      /* Select the text field */
      copyText.select()
      copyText.setSelectionRange(0, 99999) /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy")
      clearSelection()
      this.$toast.success("copied "+url+" to clipboard")
    },
    keyEnterToSubmit: function (e) {
      if (e.keyCode === 13) {
        this.invitePeople()
      }
    },
    setIsSmallScreen: function(isSmall) {
      this.isSmallScreen = isSmall
    },
    toggleSelectedContact: function(contact) {
      if (contact.invited) return

      const index = this.selectedContacts.findIndex((c) => c.email == contact.email )
      if (index >= 0) {
        this.selectedContacts.splice(index,1)
      } else {
        this.selectedContacts.push(contact)
      }
    },
    googleSignIn: async function () {
      this.authCode = await this.$gAuth.getAuthCode()
    },
    next: function (name) {
      if (name.match(/create/)) { this.$store.commit('clearProject') }
      this.$router.push(name)
    },
    invitePeople: async function() {
      const contacts = this.selectedContactsForAdding
      if (this.name && this.email) contacts.push({
        name: this.name,
        email: this.email,
      })

      if (contacts.length <= 0) {
        this.$toast.error('Please select emails below.')
        return
      }

      this.loading = true
      this.$apollo.mutate({
        mutation: INVITE_PEOPLE_WITH_PARTICIPANT_SLUG_MUTATION,
        variables: {
          participantSlug: this.participantSlug,
          participants: contacts,
        },
      }).then((data) => {
        this.loading = false
        const errors = data.data.addParticipantsWithParticipantSlug.errors
        const participants = data.data.addParticipantsWithParticipantSlug.participants
        // console.log(data)
        if (participants && participants.length > 0) {
          this.participants = this.participants.concat(participants)
          this.$toast.success('Successfully invited: '+participants.map((p) => p.email).join(','))
          this.name = null
          this.email = null
        } else {
          this.participants = this.participants.concat(this.selectedContacts)
          this.$toast.error("There was a problem with the emails you tried to invite. They may have already been invited.")
        }
        this.selectedContacts = []
        this.errorsToast(errors)
      }).catch((error) => {
        this.loading = false
        console.error(error)
        this.errorToast(error)
      })
    },
  },
  mounted: function() {
    // window.vue = this
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.view_container {
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.view_title {
  margin: 40px 0 12px 0 !important;
}

.title {
  font-family: gibsonregular;
  font-size: 24px;
  color: #151516;
  margin-bottom: 20px;
}
.description {
  font-size: 20px;
  color: #74747c;
}
.section {
  margin-top: 30px;
}

button.cta {
  font-size: 20px;
}

.controls {
  margin: 30px 0px;
}

button.done {
  font-size: 20px;
  padding: 20px 44px;
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
}

.small button {
  width: 100%;
  margin: 0px 0px 20px 0px;
}

.list {
  overflow-y: auto;
  border: 1px solid #F5F5F6;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;

  font-family: gibsonregular;
  font-size: 18px;
}

.selected_list {
  padding: 20px 4%;
  font-family: gibsonsemibold;
}
.label {
  font-size: 18px;
}

.name {
  font-family: gibsonsemibold;
}

.active .name {
  color: #000000;
}

.contact div {
  display: inline-block;
}

.email {
  float: right;
}

.small .contact .email, .small .contact .name {
  display: block;
  float:none;
  text-align: left;
  margin-bottom: 6px;
}

a {
  color: #6f63bd;
}

.contact {
  padding: 20px 4%;
  border-bottom: 1px solid #F5F5F6;
  background-color: #f8f8f8;
}

.list .active {
  cursor: pointer;
  background-color: #ffffff;
}

.list .active:hover {
  background-color: #f8f8f8;
}

.invited {
  font-family: gibsonregular;
}

.cta_secondary {
  font-size: 18px;
}

.wrapper {
  overflow: hidden;
}

.contact_list_container {
  margin-top: 30px;
  padding-top: 20px;
}

.sharing,
#header,
.view_title {
  flex: 0 1 auto;
}
.contact_list_container .list {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
